import React, { Component } from 'react'

export default class SideBar extends Component {

    render() {
      if(this.props.data){
        var name = this.props.data.name;
        var email = this.props.data.email;
        var networks= this.props.data.social.map(function(network){
          return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
        });
      }

        return (
            <div>
            <div>
              <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i /></nav>
              <aside id="colorlib-aside" className="border js-fullheight">
                <div className="text-center">
                  <div className="author-img" style={{backgroundImage: 'url(images/profilepic.jpg)'}} />
                  <h1 id="colorlib-logo"><a href="index.html"> {name}</a></h1>
                  <span className="email"><i className="icon-mail"></i> <a href="mailto"> {email} </a></span>
                </div>
                <nav id="colorlib-main-menu" role="navigation" className="navbar">
                  <div id="navbar" className="collapse">
                    <ul>
                      <li className="active"><a href="#home" data-nav-section="home">Introduction</a></li>
                      <li><a href="#about" data-nav-section="about">About</a></li>
                      <li><a href="#resume" data-nav-section="resume">Resume</a></li>
                      <li><a href="#contact" data-nav-section="contact">Contact</a></li>
                    </ul>
                  </div>
                </nav>
                <nav id="colorlib-main-menu">
                  <ul>
                    {networks}
                  </ul>
                </nav>
                <div className="colorlib-footer">
                  <p><small>
                      Made with <i className="icon-heart" aria-hidden="true" /> and <i className="icon-beer" aria-hidden="true"></i><br></br>
                      Template by <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">Colorlib</a> 
                  </small></p>
                </div>
              </aside>
            </div>
          </div>
        )
    }
}