import React, { Component } from 'react'

export default class Education extends Component {
    render() {

      if(this.props.data){
        var resumeDownload = this.props.data.resumedownload;
        var education = this.props.data.education.map(function(education){
          return <div key={education.school}><h4>{education.school}</h4>
          <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
          <p>{education.description}</p></div>
        })
        var work = this.props.data.work.map(function(work){
          return <div key={work.company}><h4>{work.company}</h4>
              <p className="info">{work.title}<span> &bull;</span> <em className="date">{work.years}</em></p>
              <p>{work.description}</p>
          </div>
        });

        // var skill = this.props.data.skills.map(function(skills){
        //   return <div key={skills.name} className="col-md-4 text-center"> 
        //           <div className="services color-1">
        //             <span className="icon">
        //                 <i className="icon-bulb" />
        //             </span>
        //             <div className="desc">
        //                 <h3>{skills.name}</h3>
        //                 <p>{skills.description}</p>
        //             </div>
        //           </div>
        //         </div>  
        // });


        var skills = this.props.data.skills.map(function(skills){
          var className = 'bar-expand '+skills.name.toLowerCase();
          return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
        })

        var certs = this.props.data.certifications.map(function(certs){
          return <li key={certs.title}><span style={{width:10}}className="info"></span><em>{certs.title}</em></li>
        });
      }


        return (
          <div>
            <section className="colorlib-experience" data-section="resume" id="resume">
              <div className="colorlib-narrow-content">
                <div className="row">
                  <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                    <span className="heading-meta">highlights</span>
                    <h2 className="colorlib-heading animate-box">Education and Work Experience</h2>
                    <p><a className="btn btn-primary btn-learn" href={resumeDownload} target="_blank" rel="noopener noreferrer">View CV<i className="icon-download4" /></a></p>
                  </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                    <h3 className="colorlib-heading animate-box"><span>Education </span></h3>
                          {education}
                  </div>
                  <div className="col-md-12">
                    <h3 className="colorlib-heading animate-box"><span>Work Experience</span></h3>
                          {work}
                  </div>
                  <div className="col-md-12">
                    <h3 className="colorlib-heading animate-box"><span>Certifications</span></h3>
                      {certs}
                  </div>
                 
                </div>
              </div>
            </section>
            <section className="colorlib-about">
              <div className="colorlib-narrow-content">
                  <div className="row">
                  <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                      <span className="heading-meta">What I do?</span>
                      <h2 className="colorlib-heading">Here are some of my expertise</h2>


                      <div className="row skill">
                        <div className="three columns header-col">
                        </div>
                        <div className="nine columns main-col">
                        <div className="bars">
                          <ul className="skills">
                            {skills}
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                  </div>
                  {/* <div className="row row-pt-md">
                    
                    {skill}
                    
                  </div> */}
              </div>
              </section>
          </div>
        )
    }
}