import React, { Component } from 'react';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from './components/sidebar';
import Introduction from './components/introduction';
import About from './components/about';
import Resume from './components/resume';
import Contact from './components/contact';
import $ from 'jquery';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {}
    };

  }

  getResumeData(){
    $.ajax({
      url:'/resumeData.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getResumeData();
  }
  
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
        <Sidebar data={this.state.resumeData.main} />
          <div id="colorlib-main">
            <Introduction data={this.state.resumeData.main} />
            <About data={this.state.resumeData.main} />
            <Resume data={this.state.resumeData.resume} />
            <Contact data={this.state.resumeData.main} />
          </div>
      	</div>
      </div>
    );
  }
}

export default App;