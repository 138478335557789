import React, { Component } from 'react'

export default class Introduction extends Component {
    render() {

      if(this.props.data){
        var name = this.props.data.name;
        var street = this.props.data.address.street;
        var city = this.props.data.address.city;
        var state = this.props.data.address.state;
        var zip = this.props.data.address.zip;
        var phonesg = this.props.data.phone_sg;
        var phoneph = this.props.data.phone_ph;
        var email = this.props.data.email;
        var resumeDownload = this.props.data.resumedownload;
        var description = this.props.data.description;
        var contactMessage = this.props.data.contactMessage;
      }

        return (
          <div>
          <section id="colorlib-hero" className="js-fullheight" data-section="home">
            <div className="flexslider js-fullheight">
              <ul className="slides">
                <li style={{backgroundImage: 'url(images/1.jpg)'}}>
                  <div className="overlay" />
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                        <div className="slider-text-inner js-fullheight">
                          <div className="desc">
                            <h1>Hi! <br />I'm {name}</h1>
                            <p> {description} </p>
                            {/* <p><a className="btn btn-primary btn-learn" href={resumeDownload} target="_blank" rel="noopener noreferrer">View CV<i className="icon-download4" /></a></p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
        )
    }
}