import React, { Component } from 'react'

export default class Contact extends Component {
    render() {

      if(this.props.data){
        var name = this.props.data.name;
        var street = this.props.data.address.street;
        var city = this.props.data.address.city;
        var state = this.props.data.address.state;
        var zip = this.props.data.address.zip;
        var phonesg = this.props.data.phone_sg;
        var phoneph = this.props.data.phone_ph;
        var email = this.props.data.email;
        var contactMessage = this.props.data.contactMessage;
      }

        return (
          <div>
          <section id="colorlib-hero" className="js-fullheight" data-section="contact">
          <div className="colorlib-narrow-content">
                <div className="row">
                <div className="col-md-12">
                    <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                    <div className="col-md-12">
                        <div className="about-desc">
                          <span className="heading-meta">Reach Out</span>
                          <h2 className="colorlib-heading">Contact Me</h2>
                          <p>
                                {contactMessage}
                            </p>
                            <p className="address">
                              <span>{name}</span><br />
                              <span>{street}<br />
                                {city} {state}, {zip}
                              </span><br />
                              <span>Singapore:{phonesg}</span><br />
                              <span>Philippines:{phoneph}</span><br />
                              <span> <a href="mailto:pageflourin@gmail.com"> {email} </a> </span>
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
          </section>
        </div>
        )
    }
}