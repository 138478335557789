import React, { Component } from 'react'

export default class About extends Component {
    render() {

      if(this.props.data){
        var bio = this.props.data.bio;
        var bio2 = this.props.data.bio2;
      }


        return (
          <div>
            <section className="colorlib-about" data-section="about">
            <div className="colorlib-narrow-content">
                <div className="row">
                <div className="col-md-12">
                    <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                    <div className="col-md-12">
                        <div className="about-desc">
                          <span className="heading-meta">About Me</span>
                          <h2 className="colorlib-heading">Who Am I?</h2>
                          <p>{bio}</p>
                          <p>{bio2}</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
          </div>
        )
      }
}